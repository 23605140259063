<template>
  <div>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :create-page-url="{ name: 'home-orders-sub-rent-create' }"
      :can-create="ACCESS_ABILITY_CREATE"
      :row-clicked="navigateToItem"
    >

      <template #cell(order_no)="{ data: { item } }">
        {{ getValueFromGivenObjectByKey(item, 'order_no', '&mdash;') }}
      </template>

      <template #cell(internal_order_reference)="{ data: { item } }">
        {{ getValueFromGivenObjectByKey(item, '-',) }}
      </template>

      <template #cell(state)="{ data: { item } }">
        <status-filter-table
          :item="item"
          :status-filter-tab="statusFilterTab"
        />
      </template>

      <template #cell(rental_start)="{ data: { item } }">
        {{ item.rental_start ? format(new Date(item.rental_start),'yyyy-MM-dd HH:mm aaa') : '-' }}
      </template>

      <template #cell(rental_end)="{ data: { item } }">
        {{ item.rental_end ? format(new Date(item.rental_end),'yyyy-MM-dd HH:mm aaa') : '-' }}
      </template>

      <template #manage-monthly-date>
        <manage-monthly-date />
      </template>

      <template #filter>
        <status-filter-color-key />
      </template>

    </l-table>
  </div>
</template>

<script>

import LTable from '@/views/components/LTable/LTable.vue'
import { getValueFromGivenObjectByKey, mapDate, formatNumberToCurrencyView } from '@core/utils/utils'
import ManageMonthlyDate from '@/views/main/orders/components/use-as-global/ManageMonthlyDate.vue'
import StatusFilterTable from '@/views/main/orders/components/statusFilterTable/StatusFilterTable.vue'
import StatusFilterColorKey from '@/views/main/orders/components/use-as-global/StatusFilterColorKey.vue'
import { format } from 'date-fns'
import quoteStatusBarConfig from '@/views/main/orders/config'
import config from '../config'

export default {
  name: 'List',
  components: {
    StatusFilterColorKey,
    StatusFilterTable,
    ManageMonthlyDate,
    LTable,
  },
  data() {
    return {
      redirectObject: {
        0: 'sub-rent-request-for-quote',
      },
    }
  },
  methods: {
    navigateToItem(item) {
      const routesPath = [
        (item && this.checkStateByCode(item.state, item.states, item.states[0]) && item.status !== this.ORDERS_STATUS_READY.status) && 'sub-rent-request-for-quote',
        (item && this.checkStateByCode(item.state, item.states, item.states[0]) && item.status === this.ORDERS_STATUS_READY.status) && 'sub-rent-request-for-quote-ready',
        (item && this.checkStateByCode(item.state, item.states, item.states[1])) && 'sub-rent-approve',
        (item && this.checkStateByCode(item.state, item.states, item.states[2])) && 'sub-rent-invoice',
      ]
      routesPath.forEach(i => {
        if (i) {
          return this.$router.push({
            name: i,
            params: { id: item.id },
          })
        }
      })
    },
  },
  setup() {
    const {
      MODULE_NAME, tableColumns, ACCESS_ABILITY_CREATE, statusFilterTab,
      checkStateByCode,
    } = config()
    const { ORDERS_STATUS_READY } = quoteStatusBarConfig()

    return {
      mapDate,
      MODULE_NAME,
      tableColumns,
      ACCESS_ABILITY_CREATE,
      statusFilterTab,
      formatNumberToCurrencyView,
      getValueFromGivenObjectByKey,
      format,
      checkStateByCode,
      ORDERS_STATUS_READY,
    }
  },
}
</script>
